import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  width: '100%',
  marginTop: '$6',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$4 0',
});

export const PaginationContainer = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  marginTop: '$2',
});

import { Heading } from '@hexa-ui/components';
import { Link } from 'react-router-dom';
import { BASENAME } from '../../Router';
import { AppMinimal } from '../../models/App';
import { AppIcon } from '../AppIcon';
import { Description, StyledCard, StyledCardContent } from './AppCard.styles';

type AppCardProps = {
  app: AppMinimal;
  safeArea?: boolean;
};

const AppCard = ({ app, safeArea = false }: AppCardProps): JSX.Element => {
  return (
    <Link to={`/${BASENAME}/${app.id}`} style={{ textDecoration: 'none', width: '100%' }}>
      <StyledCard border="small" elevated="small" data-testid="app-card">
        <StyledCardContent css={{ padding: safeArea ? '$6 $12' : '$6' }}>
          <AppIcon icon={app.icon} color={app.iconColor} size="regular" />
          <Heading size="H5" css={{ marginTop: '$2', marginBottom: '$1' }}>
            {app.name}
          </Heading>
          <Description size="xsmall" colortype="secondary">
            {app.shortDescription}
          </Description>
        </StyledCardContent>
      </StyledCard>
    </Link>
  );
};

export default AppCard;

import { EmptyState } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import errorLoading from '../../assets/error-loading.svg';
import { Container } from './ErrorComponent.styles';

const ErrorComponent = (): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <EmptyState.PageLevel
        title={formatMessage({ id: 'common.error.loadingError' })}
        customIlustration={{
          alt: 'Error',
          src: errorLoading,
        }}
        actions={[
          {
            action: () => window.location.reload(),
            name: formatMessage({ id: 'common.error.tryAgain' }),
            variant: 'secondary',
          },
        ]}
      />
    </Container>
  );
};

export default ErrorComponent;

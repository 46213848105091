import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { QueryClientProvider } from 'react-query';
import { EnvConfig, EnvProvider } from './components';
import { queryClient } from './config/reactQuery';
import { useSegmentAnalytics } from './hooks/useSegmentAnalytics';
import { IntlProvider } from './i18n';
import Router from './Router';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-app-catalog-mfe',
    seed: 'bees-hub-app-catalog-mfe'
  });

  useSegmentAnalytics(props.segmentKey);
  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <QueryClientProvider client={queryClient}>
            <Router />
          </QueryClientProvider>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}

import type { ReactNode } from 'react';
import { Container, Page } from './PageContainer.styles';

interface PageContainerProps {
  children: ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => (
  <Container>
    <Page>{children}</Page>
  </Container>
);

export default PageContainer;

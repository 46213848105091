import { Card, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const StyledCard = styled(Card, {
  width: '100%',
  height: '11.75rem',
});

export const StyledCardContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
});

export const SkeletonWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2'
})

export const Description = styled(Paragraph, {
  display: '-webkit-box',
  color: '$interfaceLabelSecondary',
  width: '100%',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
});

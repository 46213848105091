import { useIntl } from 'react-intl';

import { AppMinimal } from '@/models/App';
import { Grid, Pagination } from '@hexa-ui/components';
import { AppCard } from '../../components';
import { Container, PaginationContainer } from './AppsContainer.styles';

const { Item } = Grid;

type AppsPagination = {
  page: number;
  pageSize: number;
};

type AppsContainerProps = {
  apps: AppMinimal[];
  totalItems: number;
  appsPagination: AppsPagination;
  setAppsPagination: React.Dispatch<React.SetStateAction<AppsPagination>>;
};

const AppsContainer = ({
  apps,
  totalItems,
  appsPagination,
  setAppsPagination,
}: AppsContainerProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      {apps?.map((app) => (
        <Item key={app.id} lg={2} sm={4} xs={12}>
          <AppCard app={app} />
        </Item>
      ))}
      <PaginationContainer>
        <Pagination
          current={appsPagination.page}
          defaultCurrent={1}
          pageSize={appsPagination.pageSize}
          defaultPageSize={24}
          onChange={(page, pageSize) => setAppsPagination({ page, pageSize })}
          total={totalItems}
          pageSizeOptions={[12, 24, 48]}
          pageSizeOptionsIntl={(option) =>
            formatMessage({ id: 'common.pagination.pageSizeOptions' }, { option })
          }
          quantityIndicatorIntl={formatMessage({ id: 'common.pagination.quantityIndicator' })}
        />
      </PaginationContainer>
    </Container>
  );
};

export default AppsContainer;

import { Button, Checkbox, Heading, TextButton } from '@hexa-ui/components';
import { ChevronDown, Filter, X } from '@hexa-ui/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

import { Filters } from '@/pages/HomePage/HomePage';
import { useAnalyticsFilteredCatalog } from '../../hooks/useAnalyticsFilteredCatalog';
import {
  ActionsContainer,
  FilterButton,
  FilterContainer,
  FilterContent,
  FilterMenu,
  StyledList,
  StyledListItem,
  StyledRow,
} from './FilterDropdown.styles';

interface FilterDropdownProps {
  setAppliedFilters: React.Dispatch<React.SetStateAction<Filters>>;
  appliedFilters: Filters;
  disabled?: boolean;
  categoriesOptions: { label: string; value: string }[];
  tagsOptions: { label: string; value: string }[];
  resetPagination: () => void;
}

const defaultFilters: Filters = {
  categories: [],
  tags: [],
};

const FilterDropdown = ({
  setAppliedFilters,
  appliedFilters,
  disabled = false,
  categoriesOptions,
  tagsOptions,
  resetPagination,
}: FilterDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState<Filters>(defaultFilters);
  const { formatMessage } = useIntl();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const analyticsFilteredCatalog = useAnalyticsFilteredCatalog();
  useOnClickOutside(dropdownRef, handleCancel);

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters, isOpen]);

  const tabs = useMemo(() => {
    return [
      {
        id: 'categories',
        name: formatMessage({ id: 'homePage.categories' }),
        content: categoriesOptions,
        disabled: selectedFilters.tags.length > 0,
      },
      {
        id: 'tags',
        name: formatMessage({ id: 'homePage.tags' }),
        content: tagsOptions,
        disabled: selectedFilters.categories.length > 0,
      },
    ];
  }, [categoriesOptions, formatMessage, tagsOptions, selectedFilters]);

  function toggleDropdown() {
    setIsOpen((prevState) => !prevState);
  }

  function handleChangeFilters(checked: boolean, filterId: string) {
    const currentFilters = [...selectedFilters[tabs[activeTab].id]];
    if (checked) {
      currentFilters.push(filterId);
    } else {
      currentFilters.splice(currentFilters.indexOf(filterId), 1);
    }

    setSelectedFilters({
      ...selectedFilters,
      [tabs[activeTab].id]: currentFilters,
    });
  }

  function handleClearFilters() {
    setSelectedFilters(defaultFilters);
    setAppliedFilters(defaultFilters);
    setIsOpen(false);
    resetPagination();
  }

  function handleApplyFilters() {
    categoriesOptions.forEach((option) => {
      if (selectedFilters.categories.includes(option.value)) {
        analyticsFilteredCatalog.track({
          search: '',
          category_id: option.label,
        });
      }
    });

    setAppliedFilters(selectedFilters);
    setIsOpen(false);
    resetPagination();
  }

  function handleCancel() {
    setSelectedFilters(appliedFilters);
    setIsOpen(false);
  }

  return (
    <FilterContainer ref={dropdownRef}>
      <FilterButton
        variant="secondary"
        size="large"
        icon={() => <Filter size="medium" />}
        leading
        onClick={toggleDropdown}
        data-testid="filter-button"
        disabled={disabled}
      >
        {formatMessage({ id: 'homePage.filterButton' })}
        <ChevronDown size="medium" style={{ marginLeft: '0.5rem' }} />
      </FilterButton>
      {isOpen && (
        <FilterContent data-testid="filter-dropdown-content">
          <StyledRow css={{ width: '100%' }}>
            <StyledList>
              <li style={{ padding: '0px 16px 16px' }}>
                <Heading size="H5">{formatMessage({ id: 'homePage.categoriesOrTags' })}</Heading>
              </li>
              {tabs.map((tab, index) => (
                <StyledListItem
                  variant="selectable"
                  spacing="medium"
                  disabled={tab.disabled}
                  onClick={() => setActiveTab(index)}
                  active={activeTab === index}
                  key={tab.id}
                  data-testid={tab.id}
                >
                  {tab.name}
                  <span>{selectedFilters[tab.id].length}</span>
                </StyledListItem>
              ))}
            </StyledList>
            <FilterMenu>
              {tabs[activeTab].content?.map((option) => (
                <Checkbox
                  key={option.value}
                  label={option.label}
                  id={option.value}
                  data-testid={`${option.label}-filter-checkbox`}
                  onCheckedChange={(checked) =>
                    handleChangeFilters(checked as boolean, option.value)
                  }
                  checked={selectedFilters[tabs[activeTab].id].includes(option.value)}
                  css={{ padding: '0.5rem', '& button': { minWidth: '20px', minHeight: '20px' } }}
                />
              ))}
            </FilterMenu>
          </StyledRow>
          <ActionsContainer>
            <TextButton
              size="medium"
              icon={() => <X size="medium" />}
              iconPosition="leading"
              css={{ gap: '0.5rem' }}
              disabled={selectedFilters[tabs[activeTab].id].length === 0}
              onClick={handleClearFilters}
              data-testid="clear-filters-button"
            >
              {formatMessage({ id: 'homePage.clearFiltersButton' })}
            </TextButton>
            <StyledRow css={{ gap: '1rem' }}>
              <Button variant="secondary" onClick={handleCancel} data-testid="filter-cancel-button">
                {formatMessage({ id: 'homePage.cancelButton' })}
              </Button>
              <Button
                disabled={selectedFilters[tabs[activeTab].id].length === 0}
                onClick={handleApplyFilters}
                data-testid="filter-apply-button"
              >
                {formatMessage({ id: 'homePage.applyButton' })}
              </Button>
            </StyledRow>
          </ActionsContainer>
        </FilterContent>
      )}
    </FilterContainer>
  );
};

export default FilterDropdown;

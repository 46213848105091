import { LoadingDots } from '@hexa-ui/components';
import { PageContainer } from '../PageContainer';

const Loading = (): JSX.Element => (
  <PageContainer>
    <LoadingDots size="xlarge" dataTestId="loading-dots" css={{ paddingTop: '3.5rem' }} />
  </PageContainer>
);

export default Loading;

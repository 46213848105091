import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  categoryPage: {
    showingApps: 'Showing {count, plural, =0 {0 tool.} one {1 tool.} other {# tools.}}',
    showAll: 'Show all',
    appsLength: '{count, plural, one {1 tool} other {# tools}}',
    searchPlaceholder: 'Search by tool name or description',
    resultsFor: 'Results for "{query}"',
  },
  common: {
    error: {
      loadingError: "Content couldn't be loaded.",
      tryAgain: 'Try again',
      noAppsFound: 'No tool found.',
    },
    pagination: {
      pageSizeOptions: 'Show {option} items per page',
      quantityIndicator: 'of',
    },
  },
  breadcrumb: {
    home: 'Home',
    apps: 'BEES One tools',
  },
  appDetailsPage: {
    aboutTheApp: 'About the tool',
    description: 'Description',
    categories: 'Categories',
    tags: 'Tags',
    audience: 'Audience',
    mainFeatures: 'Main features',
    appDetails: 'Tool details',
    team: 'Team',
    supportChannel: 'Support channel',
    relatedApps: 'Related tools',
    showDetails: 'Show details',
    requestAccess: 'How to request access?',
    requestAccessModal:
      'Contact the team through the support channel in the "Management and support" section to request access to this tool.',
    gotIt: 'Got it',
    accessApp: 'Access tool',
    availability: 'Availability',
    availableIn: 'Available in {countriesNumber} countries',
    notFound: 'Tool information not found.',
    goBack: 'Go back',
  },
  homePage: {
    searchPlaceholder: 'Search by tool name or description',
    resultsFor: 'Results for "{query}"',
    resultsForTag: 'Results for tags: {tags}',
    showingApps: 'Showing {count, plural, =0 {0 tool.} one {1 tool.} other {# tools.}}',
    filterButton: 'Filters',
    categories: 'Categories',
    filterButtonTooltip: "Filters can't be applied to search results.",
    clearAllButton: 'Clear all',
    filteredBy: 'Filtered by',
    clearFiltersButton: 'Clear filters',
    cancelButton: 'Cancel',
    applyButton: 'Apply',
    tags: 'Tags',
    categoriesOrTags: 'Select categories or tags.',
  },
};

export default enUS;

import * as icons from '@hexa-ui/icons2';

export interface IIcon {
  id: number;
  name: string;
  icon: JSX.Element;
}

export const iconsList: IIcon[] = Object.entries(icons).map(([key, Component], index) => ({
  id: index,
  name: key,
  icon: <Component data-testid={key} width="100%" height="100%" color="#000" />,
}));

export const iconsMap = new Map(iconsList.map((icon) => [icon.name, icon]));

/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface ViewedApplication {
	/**
	 * Tracks when a specific id application catalog page is loaded
	 */
	app_id: string
	/**
	 * Defines whether the user has access to the application or not
	 */
	user_has_access: boolean
}
export interface SearchedCatalog {
	/**
	 * It is the text used in the search
	 */
	search: string | null
}
export interface FilteredHomepage {
	/**
	 * It is the category selected in the filter
	 */
	category_id: string | null
	/**
	 * It is the text used in the search
	 */
	search: string | null
}
export interface ShowedAllCategories {
	/**
	 * It is the category selected in the filter
	 */
	category_id: string
}
export interface AccessedRelatedApplication {
	/**
	 * Show current application
	 */
	app_from_id: string
	/**
	 * Show the related application that the user accessed
	 */
	related_app_id: string
}
export interface ClickedAccessApp {
	/**
	 * Show current application
	 */
	app_id: string
}
export interface ClickedRequestAccess {
	/**
	 * Show current application
	 */
	app_id: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef ViewedApplication
 * @property {string} app_id - Tracks when a specific id application catalog page is loaded
 * @property {boolean} user_has_access - Defines whether the user has access to the application or not
 */
/**
 * @typedef SearchedCatalog
 * @property {string | null} search - It is the text used in the search
 */
/**
 * @typedef FilteredHomepage
 * @property {string | null} category_id - It is the category selected in the filter
 * @property {string | null} search - It is the text used in the search
 */
/**
 * @typedef ShowedAllCategories
 * @property {string} category_id - It is the category selected in the filter
 */
/**
 * @typedef AccessedRelatedApplication
 * @property {string} app_from_id - Show current application
 * @property {string} related_app_id - Show the related application that the user accessed
 */
/**
 * @typedef ClickedAccessApp
 * @property {string} app_id - Show current application
 */
/**
 * @typedef ClickedRequestAccess
 * @property {string} app_id - Show current application
 */

/**
 * Tracks when a specific application catalog page is loaded
 *
 * @param {ViewedApplication} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedApplication(
	props: ViewedApplication,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Viewed Application',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the text used in the search on the catalog page
 *
 * @param {SearchedCatalog} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchedCatalog(
	props: SearchedCatalog,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Searched Catalog',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the category selected in the filter on the catalog page or the text used in the search
 *
 * @param {FilteredHomepage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filteredHomepage(
	props: FilteredHomepage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Filtered Homepage',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the selected category when the user clicks on Show all button
 *
 * @param {ShowedAllCategories} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function showedAllCategories(
	props: ShowedAllCategories,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Showed All Categories',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the related application that the user accessed
 *
 * @param {AccessedRelatedApplication} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accessedRelatedApplication(
	props: AccessedRelatedApplication,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accessed Related Application',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the current application when the user clicks on Access App
 *
 * @param {ClickedAccessApp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clickedAccessApp(
	props: ClickedAccessApp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Clicked Access App',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the current application when the user clicks on How to request access button
 *
 * @param {ClickedRequestAccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clickedRequestAccess(
	props: ClickedRequestAccess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Clicked Request Access',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Tracks when a specific application catalog page is loaded
	 *
	 * @param {ViewedApplication} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	viewedApplication,
	/**
	 * Tracks the text used in the search on the catalog page
	 *
	 * @param {SearchedCatalog} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchedCatalog,
	/**
	 * Tracks the category selected in the filter on the catalog page or the text used in the search
	 *
	 * @param {FilteredHomepage} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filteredHomepage,
	/**
	 * Tracks the selected category when the user clicks on Show all button
	 *
	 * @param {ShowedAllCategories} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	showedAllCategories,
	/**
	 * Tracks the related application that the user accessed
	 *
	 * @param {AccessedRelatedApplication} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accessedRelatedApplication,
	/**
	 * Tracks the current application when the user clicks on Access App
	 *
	 * @param {ClickedAccessApp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clickedAccessApp,
	/**
	 * Tracks the current application when the user clicks on How to request access button
	 *
	 * @param {ClickedRequestAccess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	clickedRequestAccess,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2aAfTNhJoCGU28TNVbssUETjYf4`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})

import MessageMap from '../i18n';

const deDE: MessageMap = {
  test: {
    translate: 'Übersetzen',
    missing: 'Nur Alemão-Option verfügbar',
  },
  categoryPage: {
    showingApps:
      'Es werden  {count, plural, =0 {0 apps angezeigt.} one {1 App angezeigt.} other {# Apps angezeigt.}}',
    showAll: 'Alle anzeigen',
    appsLength: '{count, plural, one {1 App} other {# Apps}}',
    searchPlaceholder: 'Suche nach App-Namen oder Beschreibung',
    resultsFor: 'Ergebnisse für "{query}"',
  },
  common: {
    error: {
      loadingError: 'Inhalt konnte nicht geladen werden.',
      tryAgain: 'Versuchen Sie es erneut',
      noAppsFound: 'Keine Apps gefunden.',
    },
    pagination: {
      pageSizeOptions: '{option} Elemente pro Seite anzeigen',
      quantityIndicator: 'von',
    },
  },
  breadcrumb: {
    home: 'Home',
    apps: 'BEES One Apps',
  },
  appDetailsPage: {
    aboutTheApp: 'Über die App',
    description: 'Beschreibung',
    categories: 'Categories',
    tags: 'Tags',
    audience: 'Zielgruppe',
    mainFeatures: 'Hauptfunktionen',
    appDetails: 'App-Details',
    team: 'Team',
    supportChannel: 'Support-Kanal',
    relatedApps: 'Verbundene Apps',
    showDetails: 'Details anzeigen',
    requestAccess: 'Wie kann man Zugriff beantragen?',
    requestAccessModal:
      'Kontaktieren Sie das Team über den Support-Kanal im Abschnitt „Management und Support“, um Zugriff auf diese App zu erhalten.',
    gotIt: 'Verstanden',
    accessApp: 'Auf App zugreifen',
    availability: 'Verfügbarkeit',
    availableIn: 'Verfügbar in {countriesNumber} Ländern',
    notFound: 'App-Informationen nicht gefunden.',
    goBack: 'Zurück',
  },
  homePage: {
    searchPlaceholder: 'Suche nach App-Namen oder Beschreibung',
    resultsFor: 'Ergebnisse für "{query}"',
    resultsForTag: 'Ergebnisse für tags: {tags}',
    showingApps:
      'Es werden  {count, plural, =0 {0 app angezeigt.} one {1 App angezeigt.} other {# Apps angezeigt.}}',
    filterButton: 'Filter',
    categories: 'Kategorien',
    filterButtonTooltip: 'Filter können nicht auf Suchergebnisse angewandt werden.',
    clearAllButton: 'Alles löschen',
    filteredBy: 'Gefiltert nach',
    clearFiltersButton: 'Filter löschen',
    cancelButton: 'Abbrechen',
    applyButton: 'Anwenden',
    tags: 'Tags',
    categoriesOrTags: 'Wählen Sie Kategorien oder Tags aus.',
  },
};

export default deDE;

export function convertToPascalCase(str: string) {
  return str
    .replace(/[-_]+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(/\s+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/\w/, function (chr) {
      return chr.toUpperCase();
    });
}

import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  position: 'relative',
  minHeight: 'calc(100vh - 3.5rem)',
});

export const Page = styled('div', {
  display: 'flex',
  padding: '$6 0',
  justifyContent: 'center',
  alignItems: 'center',
});

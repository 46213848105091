import { SkeletonLoader } from '@hexa-ui/components';
import { SkeletonWrapper, StyledCard, StyledCardContent } from './AppCard.styles';

const AppCardSkeleton = (): JSX.Element => (
  <StyledCard border="small" elevated="minimal" data-testid="app-card-skeleton" >
    <StyledCardContent css={{ gap: '$4', padding: '$4' }}>
      <SkeletonWrapper>
        <SkeletonLoader width="3rem" height="3rem" imageShape="circle" variant="image" />
        <SkeletonLoader imageShape="rectangle" variant="body" width="8.5rem" height="0.75rem" />
        <SkeletonLoader imageShape="rectangle" variant="body" width="5.5rem" height="0.75rem" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <SkeletonLoader imageShape="rectangle" variant="body" width="11.5rem" height="0.5rem" />
        <SkeletonLoader imageShape="rectangle" variant="body" width="11.5rem" height="0.5rem" />
        <SkeletonLoader imageShape="rectangle" variant="body" width="8.5rem" height="0.5rem" />
      </SkeletonWrapper>
    </StyledCardContent>
  </StyledCard>
);

export default AppCardSkeleton;

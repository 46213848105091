import segmentAnalytics from '../../../analytics';

interface useAnalyticsFilteredCatalog {
  search?: string;
  category_id?: string;
}
export const useAnalyticsFilteredCatalog = () => {
  const track = ({ search = null, category_id = null }: useAnalyticsFilteredCatalog) => {
    if (search === '') search = null;
    if (category_id === '') category_id = null;

    segmentAnalytics.filteredHomepage({
      search,
      category_id
    });
  };

  return { track };
};

import MessageMap from '../i18n';

const es419: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Sólo opción en español disponible',
  },
  categoryPage: {
    showingApps:
      'Mostrando {count, plural, =0 {0 aplicaciones.} one {1 aplicación.} other {# aplicaciones.}}',
    showAll: 'Mostrar todo',
    appsLength: '{count, plural, one {1 aplicación} other {# aplicaciones}}',
    searchPlaceholder: 'Busque por el nombre o descripción de la aplicación',
    resultsFor: 'Resultados para "{query}"',
  },
  common: {
    error: {
      loadingError: 'No se pudo cargar el contenido.',
      tryAgain: 'Inténtalo de nuevo',
      noAppsFound: 'No se encontraron aplicaciones.',
    },
    pagination: {
      pageSizeOptions: 'Mostrar {option} elementos por página',
      quantityIndicator: 'de',
    },
  },
  breadcrumb: {
    home: 'Home',
    apps: 'Aplicación BEES One',
  },
  appDetailsPage: {
    aboutTheApp: 'Acerca de la aplicación',
    description: 'Descripción',
    categories: 'Categorías',
    tags: 'Etiquetas',
    audience: 'Audiencia',
    mainFeatures: 'Características principales',
    appDetails: 'Detalles de la aplicación',
    team: 'Equipo',
    supportChannel: 'Canal de soporte',
    relatedApps: 'Aplicaciones relacionadas',
    showDetails: 'Mostrar detalles',
    requestAccess: '¿Cómo solicitar acceso?',
    requestAccessModal:
      'Comunícate con el equipo a través del canal de soporte en la sección “Gestión y soporte” para solicitar acceso a esta aplicación.',
    gotIt: 'Entendido',
    accessApp: 'Acceder a la aplicación',
    availability: 'Disponibilidad',
    availableIn: 'Disponible en {countriesNumber} países',
    notFound: 'No se encontró información de la aplicación.',
    goBack: 'Regresar',
  },
  homePage: {
    searchPlaceholder: 'Busque por el nombre o descripción de la aplicación',
    resultsFor: 'Resultados para "{query}"',
    resultsForTag: 'Resultados para etiquetas: {tags}',
    showingApps:
      'Mostrando {count, plural, =0 {0 aplicaciones.} one {1 aplicación.} other {# aplicaciones.}}',
    filterButton: 'Filtros',
    categories: 'Categorías',
    filterButtonTooltip: 'No se pueden aplicar filtros para buscar resultados.',
    clearAllButton: 'Borrar todo',
    filteredBy: 'Filtrado por',
    clearFiltersButton: 'Borrar filtros',
    cancelButton: 'Cancelar',
    applyButton: 'Aplicar',
    tags: 'Etiquetas',
    categoriesOrTags: 'Selecciona categorías o etiquetas.',
  },
};

export default es419;

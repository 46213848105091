import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loading } from './components';

const CategoryPage = lazy(() => import('./pages/CategoryPage/CategoryPage'));
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const AppDetailsPage = lazy(() => import('./pages/AppDetailsPage/AppDetailsPage'));

export const BASENAME = 'tool-library';

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={BASENAME}
          element={
            <Suspense fallback={<Loading />}>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path={`${BASENAME}/category/:categoryId`}
          element={
            <Suspense fallback={<Loading />}>
              <CategoryPage />
            </Suspense>
          }
        />
        <Route
          path={`${BASENAME}/:appId`}
          element={
            <Suspense fallback={<Loading />}>
              <AppDetailsPage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;

import MessageMap from '../i18n';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Apenas opção em português disponível',
  },
  categoryPage: {
    showingApps:
      'Mostrando {count, plural, =0 {0 aplicativos.} one {1 aplicativo.} other {# aplicativos.}}',
    showAll: 'Mostrar tudo',
    appsLength: '{count, plural, one {1 aplicativo} other {# aplicativos}}',
    searchPlaceholder: 'Busque pelo nome ou descrição do aplicativo',
    resultsFor: 'Resultados para "{query}"',
  },
  common: {
    error: {
      loadingError: 'O conteúdo não pôde ser carregado.',
      tryAgain: 'Tente novamente',
      noAppsFound: 'Nenhum aplicativo encontrado.',
    },
    pagination: {
      pageSizeOptions: 'Mostrar {option} itens por página',
      quantityIndicator: 'de',
    },
  },
  breadcrumb: {
    home: 'Home',
    apps: 'Aplicativos BEES One',
  },
  appDetailsPage: {
    aboutTheApp: 'Sobre o aplicativo',
    description: 'Descrição',
    categories: 'Categorias',
    tags: 'Etiquetas',
    audience: 'Público',
    mainFeatures: 'Recursos principais',
    appDetails: 'Detalhes do aplicativo',
    team: 'Equipe',
    supportChannel: 'Canal de suporte',
    relatedApps: 'Aplicativos relacionados',
    showDetails: 'Mostrar detalhes',
    requestAccess: 'Como solicitar acesso?',
    requestAccessModal:
      'Entre em contato com a equipe pelo canal de suporte, (seção Gerenciamento e suporte) para solicitar acesso a este aplicativo.',
    gotIt: 'Entendi',
    accessApp: 'Acessar aplicativo',
    availability: 'Disponibilidade',
    availableIn: 'Disponível em {countriesNumber} países',
    notFound: 'Informações do aplicativo não encontradas.',
    goBack: 'Voltar',
  },
  homePage: {
    searchPlaceholder: 'Busque pelo nome ou descrição do aplicativo',
    resultsFor: 'Resultados para "{query}"',
    resultsForTag: 'Resultados para etiquetas: {tags}',
    showingApps:
      'Mostrando {count, plural, =0 {0 aplicativos.} one {1 aplicativo.} other {# aplicativos.}}',
    filterButton: 'Filtros',
    categories: 'Categorias',
    filterButtonTooltip: 'Não é possível aplicar os filtros aos resultados da pesquisa.',
    clearAllButton: 'Apagar tudo',
    filteredBy: 'Filtrado por',
    clearFiltersButton: 'Limpar filtros',
    cancelButton: 'Cancelar',
    applyButton: 'Aplicar',
    tags: 'Etiquetas',
    categoriesOrTags: 'Selecione categorias ou etiquetas.',
  },
};

export default ptBR;

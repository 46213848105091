import { convertToPascalCase } from '../../utils/helpers';
import { StyledIconContainer } from './AppIcon.styles';
import { iconsMap } from './iconsList';

interface AppIconProps {
  icon: string;
  color?: string;
  size?: 'small' | 'regular';
  dataTestId?: string;
}

export const getIcon = (icon: string) => {
  const applicationIcon = iconsMap.get(convertToPascalCase(icon));

  return applicationIcon?.icon ?? <></>;
};

const AppIcon = ({ icon, color, dataTestId, size = 'regular' }: AppIconProps): JSX.Element => {
  const IconComponent = getIcon(icon);

  return (
    <StyledIconContainer
      size={size}
      css={{ backgroundColor: color || '#ffff00' }}
      data-testid={dataTestId}
    >
      {IconComponent}
    </StyledIconContainer>
  );
};

export default AppIcon;

import MessageMap from '../i18n';

const koKR: MessageMap = {
  test: {
    translate: '번역하다',
    missing: '한국어 옵션만 가능',
  },
  categoryPage: {
    showingApps: '{count}개 앱 표시.',
    showAll: '모두 표시',
    appsLength: '{count}개의 앱',
    searchPlaceholder: '앱 이름이나 설명으로 검색하십시오',
    resultsFor: '에 대한 결과 "{query}"',
  },
  common: {
    error: {
      loadingError: '콘텐츠를 불러올 수 없습니다.',
      tryAgain: '다시 시도해보세요.',
      noAppsFound: '앱을 찾을 수 없음.',
    },
    pagination: {
      pageSizeOptions: ' 페이지당 {option}개 항목 표시',
      quantityIndicator: '중',
    },
  },
  breadcrumb: {
    home: 'Home',
    apps: 'BEES One 앱',
  },
  appDetailsPage: {
    aboutTheApp: '앱 정보',
    description: '설명',
    categories: '카테고리',
    tags: '태그',
    audience: '대상',
    mainFeatures: '주요 기능',
    appDetails: '앱 상세 정보',
    team: 'Equipe',
    supportChannel: '지원 채널',
    relatedApps: '관련 앱',
    showDetails: '상세 표시',
    requestAccess: '액세스 권한은 어떻게 요청하나요?',
    requestAccessModal:
      '관리 및 지원 섹션의 지원 채널을 통해 지원 팀에 문의하여 이 앱에 대한 액세스 권한을 요청하세요.',
    gotIt: '알겠습니다',
    accessApp: '앱 액세스',
    availability: '가용성',
    availableIn: '{countriesNumber}개국에서 사용 가능',
    notFound: '앱 정보를 찾을 수 없습니다.',
    goBack: '뒤로 가기',
  },
  homePage: {
    searchPlaceholder: '앱 이름이나 설명으로 검색하십시오',
    resultsFor: '에 대한 결과 "{query}"',
    resultsForTag: '태그 에 대한 결과: {tags}',
    showingApps: '{count}개 앱 표시.',
    filterButton: '필터',
    categories: '카테고리',
    filterButtonTooltip: '검색 결과에 필터를 적용할 수 없습니다.',
    clearAllButton: '모두 지우기',
    filteredBy: '필터링 기준',
    clearFiltersButton: '필터 지우기',
    cancelButton: '취소',
    applyButton: '적용',
    tags: '태그',
    categoriesOrTags: '카테고리 또는 태그를 선택합니다.',
  },
};

export default koKR;

import { Tooltip } from '@hexa-ui/components';

export declare type TooltipPlacement = 'bottom' | 'left' | 'right' | 'top';

interface CustomTooltipProps {
  children: React.ReactNode;
  text: string;
  placement?: TooltipPlacement;
  disabled?: boolean;
}

const CustomTooltip = ({
  children,
  text,
  placement = 'bottom',
  disabled = false,
}: CustomTooltipProps) => {
  if (disabled) return <>{children}</>;

  return (
    <Tooltip placement={placement} text={text}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;

import { styled } from '@hexa-ui/theme';

export const StyledIconContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$full',
  variants: {
    size: {
      small: {
        width: '1.5rem',
        height: '1.5rem',
        '& svg': {
          width: '1rem',
          height: '1rem',
        },
      },
      regular: {
        width: '3rem',
        height: '3rem',
        '& svg': {
          width: '2rem',
          height: '2rem',
        },
      },
    },
  },
});

import { EmptyState } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import notFound from '../../assets/not-found.svg';
import { Container } from './../ErrorComponent/ErrorComponent.styles';

export const NotFoundComponent = (): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <Container data-testid="not-found-component">
      <EmptyState.PageLevel
        description={formatMessage({ id: 'common.error.noAppsFound' })}
        customIlustration={{
          alt: formatMessage({ id: 'common.error.noAppsFound' }),
          src: notFound,
        }}
      />
    </Container>
  );
};

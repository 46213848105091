import { Button, ListItem } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const FilterButton = styled(Button, {
  borderColor: 'rgba(0, 0, 0, 0.16) !important',
  '& > div': {
    background: 'transparent !important',
    marginRight: 0,
  },
});

export const FilterContainer = styled('div', {
  position: 'relative',
  zIndex: '$2',
});

export const FilterContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 'calc(100% + 8px)',
  left: 0,
  backgroundColor: '$neutral0',
  borderRadius: '$4',
  boxShadow: '$2',
  width: '510px',
});

export const StyledRow = styled('div', {
  display: 'flex',
});

export const StyledList = styled('ul', {
  listStyle: 'none',
  padding: '1rem 0',
  margin: 0,
  width: '100%',
  maxWidth: '242px',
  minHeight: '100%',
  borderRight: '1px solid #DBDADA',
});

export const StyledListItem = styled(ListItem, {
  '&>div>div': {
    justifyContent: 'space-between',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$semanticNeutralBackground',
      },
      false: {},
    },
  },
});

export const FilterMenu = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  gap: '0.5rem',
  width: '100%',
  height: '240px',
  overflowY: 'auto',
});

export const ActionsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1.5rem',
  borderTop: '1px solid #DBDADA',
});
